let headImg = [
  {
      drinkImg : "../../images/drinks/Coffee-Espresso1.png",
      category: "Coffee Espresso"
    },
      {
      drinkImg : "../../images/drinks/IcedBeverages1.png",
      category: "Iced Beverages"
    },
      {
      drinkImg : "../../images/drinks/iceTea1.png",
      category: "Ice Tea"
    },
      {
      drinkImg : "../../images/drinks/refreshers1.png",
      category: "refresher"
    },
      {
      drinkImg : "../../images/drinks/Tea-Chocolate1.png",
      category: "Tea Chocolate"
    }
  ]
  export default headImg;