let drinksMenu = [
  {
    drinkImg: "../../images/drinks/Coffee-Espresso1.png",
    drinkName: "Caffè Mocha",
    category: "Coffee Espresso",
    price: 17.99,
    description: '5 calories, 0g sugar, 0g fat',
    star:'200★item',
    itemID: 1,
  },
  {
    drinkImg: "../../images/drinks/Coffee-Espresso2.png",
    drinkName: "Caffè Americano/Long Black",
    category: "Coffee Espresso",
    price: 15.99,
    description: '15 calories, 0g sugar, 0g fat',
    star:'200★item',
    itemID: 2,
  },
  {
    drinkImg: "../../images/drinks/Coffee-Espresso3.png",
    drinkName: "Cappuccino",
    category: "Coffee Espresso",
    price: 13.99, 
    description: '140 calories, 12g sugar, 5g fat',
    star:'200★item',
    itemID: 3,
  },
  {
    drinkImg: "../../images/drinks/Coffee-Espresso4.png",
    drinkName: "Caffè Latte",
    category: "Coffee Espresso",
    price: 20.99, 
    description: '190 calories, 18g sugar, 7g fat',
    star:'200★item',
    itemID: 4,
  },
  {
    drinkImg: "../../images/drinks/Coffee-Espresso5.png",
    drinkName: "Caramel Macchiato",
    category: "Coffee Espresso",
    price: 23.99,
    description: '250 calories, 33g sugar, 7g fat',
    star:'200★item', 
    itemID: 5,
  },
  {
    drinkImg: "../../images/drinks/Coffee-Espresso6.png",
    drinkName: "Batch Brew",
    category: "Coffee Espresso",
    price: 11.99,
    description: '190 calories, 18g sugar, 7g fat',
    star:'200★item', 
    itemID: 6,
  },
  {
    drinkImg: "../../images/drinks/Coffee-Espresso7.png",
    drinkName: "Pumpkin Spice Latte",
    category: "Coffee Espresso",
    price: 18.99,
    description: '390 calories, 50g sugar, 14g fat',
    star:'200★item', 
    itemID: 7,
  },
  {
    drinkImg: "../../images/drinks/Coffee-Espresso8.png",
    drinkName: "Pumpkin Latte",
    category: "Coffee Espresso",
    price: 17.99,
    description: '390 calories, 50g sugar, 14g fat',
    star:'200★item',  
    itemID: 8,
  },
  {
    drinkImg: "../../images/drinks/IcedBeverages1.png",
    drinkName: "Brown Sugar Iced Shaken Espresso",
    category: "Iced Beverages",
    price: 22.99, 
    description: '120 calories, 12g sugar, 3g fat',
    star:'200★item',  
    itemID: 9,
  },
  {
    drinkImg: "../../images/drinks/IcedBeverages2.png",
    drinkName: "Iced Caramel Macchiato",
    category: "Iced Beverages",
    price: 21.99, 
    description: '250 calories, 34g sugar, 7g fat',
    star:'200★item',  
    itemID: 10,
  },
  {
    drinkImg: "../../images/drinks/IcedBeverages3.png",
    drinkName: "Iced Cappuccino",
    category: "Iced Beverages",
    price: 20.99, 
    description: '250 calories, 32g sugar, 12g fat',
    star:'200★item',  
    itemID: 11,
  },
  {
    drinkImg: "../../images/drinks/IcedBeverages4.png",
    drinkName: "Iced Americano",
    category: "Iced Beverages",
    price: 22.99, 
    description: '250 calories, 32g sugar, 12g fat',
    star:'200★item',  
    itemID: 12,
  },
  {
    drinkImg: "../../images/drinks/IcedBeverages5.png",
    drinkName: "Iced Caffè Mocha",
    category: "Iced Beverages",
    price: 23.99, 
    description: '350 calories, 30g sugar, 17g fat',
    star:'200★item',  
    itemID: 13,
  },
  {
    drinkImg: "../../images/drinks/IcedBeverages6.png",
    drinkName: "Dirty Chai Iced Shaken Espresso",
    category: "Iced Beverages",
    price: 25.99, 
    description: '100 calories, 14g sugar, 2g fat',
    star:'200★item',  
    itemID: 14,
  },
  {
    drinkImg: "../../images/drinks/IcedBeverages7.png",
    drinkName: "Iced Americano Tea",
    category: "Iced Beverages",
    price: 25.99, 
    description: '250 calories, 32g sugar, 12g fat',
    star:'200★item',  
    itemID: 15,
  },
  {
    drinkImg: "../../images/drinks/IcedBeverages8.png",
    drinkName: "Iced White Chocolate",
    category: "Iced Beverages",
    price: 25.99, 
    description: '420 calories, 48g sugar, 20g fat',
    star:'200★item',  
    itemID: 16,
  },
  {
    drinkImg: "../../images/drinks/iceTea1.png",
    drinkName: "Iced Shaken Black Tea Lemonade",
    category: "Ice Tea",
    price: 15.99, 
    description: '140 calories, 20g sugar, 2g fat',
    star:'200★item',  
    itemID: 17,
  },
  {
    drinkImg: "../../images/drinks/iceTea2.png",
    drinkName: "Iced Chai Tea Latte",
    category: "Ice Tea",
    price: 13.99, 
    description: '140 calories, 25g sugar, 2.5g fat',
    star:'200★item',  
    itemID: 18,
  },
  {
    drinkImg: "../../images/drinks/iceTea3.png",
    drinkName: "Iced Dirty Chai Latte",
    category: "Ice Tea",
    price: 17.99, 
    description: '250 calories, 32g sugar, 12g fat',
    star:'200★item',  
    itemID: 19,
  },
  {
    drinkImg: "../../images/drinks/iceTea4.png",
    drinkName: "Iced Red Chai Latte",
    category: "Ice Tea",
    price: 17.99, 
    description: '50 calories, 11g sugar, 0g fat',
    star:'200★item',  
    itemID: 20,
  },
  {
    drinkImg: "../../images/drinks/refreshers1.png",
    drinkName: "Pink Drink",
    category: "refresher",
    price: 30.99, 
    description: '140 calories, 32g sugar, 0g fat',
    star:'200★item',  
    itemID: 21,
  },
  {
    drinkImg: "../../images/drinks/refreshers2.png",
    drinkName: "Mango Dragonfruit Refresher",
    category: "refresher",
    price: 29.99, 
    description: '118 calories, 24g sugar, 0g fat',
    star:'200★item',  
    itemID: 22,
  },
  {
    drinkImg: "../../images/drinks/refreshers3.png",
    drinkName: "Dragon Drink",
    category: "refresher",
    price: 33.99, 
    description: '130 calories, 23g sugar, 3g fat',
    star:'200★item',  
    itemID: 23,
  },
  {
    drinkImg: "../../images/drinks/refreshers4.png",
    drinkName: "Lion Drink",
    category: "refresher",
    price: 31.99, 
    description: '125 calories, 25g sugar, 5g fat',
    star:'200★item',  
    itemID: 24,
  },
  {
    drinkImg: "../../images/drinks/Tea-Chocolate1.png",
    drinkName: "Espresso & Matcha Fusion",
    category: "Tea Chocolate",
    price: 18.99, 
    description: '240 calories, 32g sugar, 7g fat',
    star:'200★item',  
    itemID: 25,
  },
  {
    drinkImg: "../../images/drinks/Tea-Chocolate2.png",
    drinkName: "Black Tea with Ruby Grapefruit & Honey",
    category: "Tea Chocolate",
    price: 19.99, 
    description: '130 calories, 30g sugar, 0g fat',
    star:'200★item',  
    itemID: 26,
  },
  {
    drinkImg: "../../images/drinks/Tea-Chocolate3.png",
    drinkName: "Classic Hot Chocolate",
    category: "Tea Chocolate",
    price: 18.99, 
    description: '240 calories, 42g sugar, 4.5g fat',
    star:'200★item',  
    itemID: 27,
  },
  {
    drinkImg: "../../images/drinks/Tea-Chocolate4.png",
    drinkName: "Earl Grey Tea",
    category: "Tea Chocolate",
    price: 17.99, 
    description: '0 calories, 0g sugar, 0g fat',
    star:'200★item',  
    itemID: 28,
  },
  {
    drinkImg: "../../images/drinks/Tea-Chocolate5.png",
    drinkName: "English Breakfast Tea Latte",
    category: "Tea Chocolate",
    price: 20.99, 
    description: '150 calories, 21g sugar, 4g fat',
    star:'200★item',  
    itemID: 29,
  },
  {
    drinkImg: "../../images/drinks/Tea-Chocolate6.png",
    drinkName: "White Hot Chocolate",
    category: "Tea Chocolate",
    price: 15.99, 
    description: '310 calories, 54g sugar, 5g fat',
    star:'200★item',  
    itemID: 30,
  },
  {
    drinkImg: "../../images/drinks/Tea-Chocolate7.png",
    drinkName: "White Hot Cream",
    category: "Tea Chocolate",
    price: 15.99, 
    description: '310 calories, 54g sugar, 6g fat',
    star:'200★item',  
    itemID: 31,
  },
  {
    drinkImg: "../../images/drinks/Tea-Chocolate8.png",
    drinkName: "Mint Majesty Herbal Tea",
    category: "Tea Chocolate",
    price: 17.99, 
    description: '0 calories, 0g sugar, 0g fat',
    star:'200★item',  
    itemID: 32,
  },
];
export default drinksMenu;





// let drinksMenu = [
//   {
//     drinkImg: "../../images/drinks/Coffee-Espresso1.png",
//     drinkName: "Velvet Mochaccino",
//     category: "Coffee Espresso",
//     price: 17.99,
//     description: '5 calories, 0g sugar, 0g fat',
//     star:'200★item',
//     itemID: 1,
//   },
//   {
//     drinkImg: "../../images/drinks/Coffee-Espresso2.png",
//     drinkName: "Americano Bliss",
//     category: "Coffee Espresso",
//     price: 15.99,
//     description: '15 calories, 0g sugar, 0g fat',
//     star:'200★item',
//     itemID: 2,
//   },
//   {
//     drinkImg: "../../images/drinks/Coffee-Espresso3.png",
//     drinkName: "Frothy Cappella",
//     category: "Coffee Espresso",
//     price: 13.99, 
//     description: '140 calories, 12g sugar, 5g fat',
//     star:'200★item',
//     itemID: 3,
//   },
//   {
//     drinkImg: "../../images/drinks/Coffee-Espresso4.png",
//     drinkName: "Latte Luxe",
//     category: "Coffee Espresso",
//     price: 20.99, 
//     description: '190 calories, 18g sugar, 7g fat',
//     star:'200★item',
//     itemID: 4,
//   },
//   {
//     drinkImg: "../../images/drinks/Coffee-Espresso5.png",
//     drinkName: "Caramel Dreamcatcher",
//     category: "Coffee Espresso",
//     price: 23.99,
//     description: '250 calories, 33g sugar, 7g fat',
//     star:'200★item', 
//     itemID: 5,
//   },
//   {
//     drinkImg: "../../images/drinks/Coffee-Espresso6.png",
//     drinkName: "Artisan Brew",
//     category: "Coffee Espresso",
//     price: 11.99,
//     description: '190 calories, 18g sugar, 7g fat',
//     star:'200★item', 
//     itemID: 6,
//   },
//   {
//     drinkImg: "../../images/drinks/Coffee-Espresso7.png",
//     drinkName: "Harvest Spice Delight",
//     category: "Coffee Espresso",
//     price: 18.99,
//     description: '390 calories, 50g sugar, 14g fat',
//     star:'200★item', 
//     itemID: 7,
//   },
//   {
//     drinkImg: "../../images/drinks/Coffee-Espresso8.png",
//     drinkName: "Pumpkin Elixir",
//     category: "Coffee Espresso",
//     price: 17.99,
//     description: '390 calories, 50g sugar, 14g fat',
//     star:'200★item',  
//     itemID: 8,
//   },
//   {
//     drinkImg: "../../images/drinks/IcedBeverages2.png",
//     drinkName: "Iced Caramel Macchiato",
//     category: "Iced Beverages",
//     price: 21.99, 
//     description: '250 calories, 34g sugar, 7g fat',
//     star:'200★item',  
//     itemID: 10,
//   },
//   {
//     drinkImg: "../../images/drinks/IcedBeverages1.png",
//     drinkName: "Shiverin' Shaken Espresso",
//     category: "Iced Beverages",
//     price: 22.99, 
//     description: '120 calories, 12g sugar, 3g fat',
//     star:'200★item',  
//     itemID: 9,
//   },
//   {
//     drinkImg: "../../images/drinks/IcedBeverages2.png",
//     drinkName: "Caramel Frost Bliss",
//     category: "Iced Beverages",
//     price: 21.99, 
//     description: '250 calories, 34g sugar, 7g fat',
//     star:'200★item',  
//     itemID: 10,
//   },
//   {
//     drinkImg: "../../images/drinks/IcedBeverages3.png",
//     drinkName: "Frosty Froth Delight",
//     category: "Iced Beverages",
//     price: 20.99, 
//     description: '250 calories, 32g sugar, 12g fat',
//     star:'200★item',  
//     itemID: 11,
//   },
//   {
//     drinkImg: "../../images/drinks/IcedBeverages4.png",
//     drinkName: "Chilled Ameri-bliss",
//     category: "Iced Beverages",
//     price: 22.99, 
//     description: '250 calories, 32g sugar, 12g fat',
//     star:'200★item',  
//     itemID: 12,
//   },
//   {
//     drinkImg: "../../images/drinks/IcedBeverages5.png",
//     drinkName: "Icy Mocha Cascade",
//     category: "Iced Beverages",
//     price: 23.99, 
//     description: '350 calories, 30g sugar, 17g fat',
//     star:'200★item',  
//     itemID: 13,
//   },
//   {
//     drinkImg: "../../images/drinks/IcedBeverages6.png",
//     drinkName: "Chai Blizzard",
//     category: "Iced Beverages",
//     price: 25.99, 
//     description: '100 calories, 14g sugar, 2g fat',
//     star:'200★item',  
//     itemID: 14,
//   },
//   {
//     drinkImg: "../../images/drinks/IcedBeverages7.png",
//     drinkName: "Frosty Iced Tea Cascade",
//     category: "Iced Beverages",
//     price: 25.99, 
//     description: '250 calories, 32g sugar, 12g fat',
//     star:'200★item',  
//     itemID: 15,
//   },
//   {
//     drinkImg: "../../images/drinks/IcedBeverages8.png",
//     drinkName: "Arctic White Chocolate Swirl",
//     category: "Iced Beverages",
//     price: 25.99, 
//     description: '420 calories, 48g sugar, 20g fat',
//     star:'200★item',  
//     itemID: 16,
//   },
//   {
//     drinkImg: "../../images/drinks/iceTea1.png",
//     drinkName: "Lemon Zest Black Iced Tango",
//     category: "Ice Tea",
//     price: 15.99, 
//     description: '140 calories, 20g sugar, 2g fat',
//     star:'200★item',  
//     itemID: 17,
//   },
//   {
//     drinkImg: "../../images/drinks/iceTea2.png",
//     drinkName: "Chai Symphony on Ice",
//     category: "Ice Tea",
//     price: 13.99, 
//     description: '140 calories, 25g sugar, 2.5g fat',
//     star:'200★item',  
//     itemID: 18,
//   },
//   {
//     drinkImg: "../../images/drinks/iceTea3.png",
//     drinkName: "Chillaxed Dirty Chai Splash",
//     category: "Ice Tea",
//     price: 17.99, 
//     description: '250 calories, 32g sugar, 12g fat',
//     star:'200★item',  
//     itemID: 19,
//   },
//   {
//     drinkImg: "../../images/drinks/iceTea4.png",
//     drinkName: "Red Velvet Chai Frost",
//     category: "Ice Tea",
//     price: 17.99, 
//     description: '50 calories, 11g sugar, 0g fat',
//     star:'200★item',  
//     itemID: 20,
//   },
//   {
//     drinkImg: "../../images/drinks/refreshers1.png",
//     drinkName: "Blushful Berry Burst",
//     category: "Refresher",
//     price: 30.99, 
//     description: '140 calories, 32g sugar, 0g fat',
//     star:'200★item',  
//     itemID: 21,
//   },
//   {
//     drinkImg: "../../images/drinks/refreshers2.png",
//     drinkName: "Tropical Dragon Bliss",
//     category: "Refresher",
//     price: 29.99, 
//     description: '118 calories, 24g sugar, 0g fat',
//     star:'200★item',  
//     itemID: 22,
//   },
//   {
//     drinkImg: "../../images/drinks/refreshers3.png",
//     drinkName: "Mythical Dragon Elixir",
//     category: "Refresher",
//     price: 33.99, 
//     description: '130 calories, 23g sugar, 3g fat',
//     star:'200★item',  
//     itemID: 23,
//   },
//   {
//     drinkImg: "../../images/drinks/refreshers4.png",
//     drinkName: "Lion's Mane Lusciousness",
//     category: "Refresher",
//     price: 31.99, 
//     description: '125 calories, 25g sugar, 5g fat',
//     star:'200★item',  
//     itemID: 24,
//   },
//   {
//     drinkImg: "../../images/drinks/Tea-Chocolate1.png",
//     drinkName: "Matcha Marvel Fusion",
//     category: "Tea Chocolate",
//     price: 18.99, 
//     description: '240 calories, 32g sugar, 7g fat',
//     star:'200★item',  
//     itemID: 25,
//   },
//   {
//     drinkImg: "../../images/drinks/Tea-Chocolate2.png",
//     drinkName: "Ruby Citrus Serenade",
//     category: "Tea Chocolate",
//     price: 19.99, 
//     description: '130 calories, 30g sugar, 0g fat',
//     star:'200★item',  
//     itemID: 26,
//   },
//   {
//     drinkImg: "../../images/drinks/Tea-Chocolate3.png",
//     drinkName: "Velvet Hot Chocolate Symphony",
//     category: "Tea Chocolate",
//     price: 18.99, 
//     description: '240 calories, 42g sugar, 4.5g fat',
//     star:'200★item',  
//     itemID: 27,
//   },
//   {
//     drinkImg: "../../images/drinks/Tea-Chocolate4.png",
//     drinkName: "Earl's Enigma Elixir",
//     category: "Tea Chocolate",
//     price: 17.99, 
//     description: '0 calories, 0g sugar, 0g fat',
//     star:'200★item',  
//     itemID: 28,
//   },
//   {
//     drinkImg: "../../images/drinks/Tea-Chocolate5.png",
//     drinkName: "Breakfast Sonata Latte",
//     category: "Tea Chocolate",
//     price: 20.99, 
//     description: '150 calories, 21g sugar, 4g fat',
//     star:'200★item',  
//     itemID: 29,
//   },
//   {
//     drinkImg: "../../images/drinks/Tea-Chocolate6.png",
//     drinkName: "Snowy White Chocolate Symphony",
//     category: "Tea Chocolate",
//     price: 15.99, 
//     description: '310 calories, 54g sugar, 5g fat',
//     star:'200★item',  
//     itemID: 30,
//   },
//   {
//     drinkImg: "../../images/drinks/Tea-Chocolate7.png",
//     drinkName: "Creamy White Delight",
//     category: "Tea Chocolate",
//     price: 15.99, 
//     description: '310 calories, 54g sugar, 6g fat',
//     star:'200★item',  
//     itemID: 31,
//   },
//   {
//     drinkImg: "../../images/drinks/Tea-Chocolate8.png",
//     drinkName: "Minty Majesty Medley",
//     category: "Tea Chocolate",
//     price: 17.99, 
//     description: '0 calories, 0g sugar, 0g fat',
//     star:'200★item',  
//     itemID: 32,
//   },
// ];
// export default drinksMenu;





